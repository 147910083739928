<template>
  <div class="page-reception-show-af bg-main-af">
    <div class="box-top">
      <div class="fixed">
        <div class="box-tab">
          <span v-for="(item,idx) in getTabs" :key="idx"
            :class="item.id==curTab ? 'sel' : ''"
             @click="onChangeTab(item.id)"
                >{{item.title}}<i></i></span>
        </div>
      </div>
    </div>
    <div v-if="!role.isAfSeller" class="box-info">
      <p>
      <span><strong>接待人员</strong>{{user.name}}</span>
      <span style="max-width: 4rem;"><strong>总分</strong>{{user.score}}</span>
      </p>
      <p>
      <span><strong>公司名称</strong>{{user.company_name}}</span>
      </p>
    </div>
    <div v-if="curTab=='t1'">
      <div style="padding: 0 0.6rem;">
        <el-table class="tbl-order" 
          :data="orderList"
          style="width: 100%">
          <el-table-column
            prop="order_no"
            width="110"
            label="单据号"
            >
          </el-table-column>
          <el-table-column
            prop="order_type"
            width="80"
            label="单据种类"
            >
          </el-table-column>
          <el-table-column
            prop="datetime"
            width="80"
            label="开单时间">
          </el-table-column>
          <el-table-column
            prop="tag_name"
            label="标签名称">
          </el-table-column>
        </el-table>
      </div>
      <div class="sec">
        <div class="box-score">
        <div class="box-bg">
          <div class="round" :style="`transform: rotate(${score * 1.8}deg);`"> <i></i> </div>
          <div class="ruling">
            <i v-for="(item, idx) in rules" :key="idx" :style="`transform: rotate(${idx * 36}deg);`"></i> 
          </div>
          <div class="bg-gray"></div>
        </div>
          <div class="mask">
          <div class="score">
            <div><strong>{{score || '-'}}</strong></div>
            <div style="color: #969799;">总分</div>
          </div>
          </div>
        </div>
      </div>
      <div class="box-hit" v-for="(type, index) in checkResult">
        <div class="type"><i></i> {{type.business}}</div>
        <div class="box-item">
        <div v-for="(item) in type.checkResultList" :key="item.id">
            <div class="title">{{item.title}}</div>
            <div class="box-list" v-for="(list, idx) in item.hitResult" :key="idx">
              <div class="sub-title">{{list.title}}</div>
              <div class="box-tag">
                <span class="tag" v-for="(tag) in list.children"
                  @click="setStartTime(tag.time)"
                  :style="{cursor: tag.state ? 'pointer' : ''}"
                  :key="tag.title">
                  {{tag.title}}
                  <img v-if="tag.state" src="@/assets/af_i_right.png"  class="i-check"/>
                </span>
              </div>
            </div>
        </div>
        </div>
      </div>
      <!--
      <div class="box-hit">
        <div class="box-item" v-for="(item) in checkResult" :key="item.id">
            <div class="title">{{item.title}}</div>
            <div class="sec">
            <div class="box-list" v-for="(list, idx) in item.hitResult" :key="idx">
              <div class="sub-title">{{list.title}}</div>
              <div class="box-tag">
                <span class="tag" v-for="(tag) in list.children"
                  @click="setStartTime(tag.time)"
                  :style="{cursor: tag.state ? 'pointer' : ''}"
                  :key="tag.title">
                  {{tag.title}}
                  <img v-if="tag.state" src="@/assets/af_i_right.png"  class="i-check"/>
                </span>
              </div>
            </div>
            </div>
        </div>
      </div>
      -->
    </div>

      <TextRecord v-if="curTab=='t2'" />
      <CustomFile v-if="curTab=='t3'" />
      <DealStep v-if="curTab=='t4'" />
  </div>    
</template>

<script>
import {getRole} from '@/utils/getRole'
import { postForm } from '@/api'
import TextRecord from './textRecord.vue';
import DealStep from './dealStep.vue';
import CustomFile from './customFile.vue';
const color = ['#377EEA','#FAC15C','#11C16F','#ED5A54','#54BFED', '#377EEA','#FAC15C','#11C16F','#ED5A54','#54BFED']

export default {
  data() {
      return {
        role: getRole(),
        color,
        id: '',
        curTab: 't1',
        detail: {},
        user: {},
        checkResult: [],
        score: 0,
        rules: [...new Array(5)],
        orderList: [
        ],
      }
  },
  computed: {
    getTabs() {
      if (this.isVolvo) {
        return [
          {title: '环节评定', id: 't1'},
          {title: '语音复盘', id: 't2'},
          {title: '客户档案', id: 't3'},
        ];
      }
      return [
        {title: '环节评定', id: 't1'},
        {title: '语音复盘', id: 't2'},
        {title: '客户档案', id: 't3'},
        //{title: '成交阶段', id: 't4'},
      ];
    }
  },
  components: {
    TextRecord,
    DealStep,
    CustomFile,
  },
  methods: {
    onChangeTab(id) {
      if (id == this.curTab) {
        return;
      }
      this.curTab = id;
      this.$router.replace({name: 'afReceptionShow', query: {id: this.id, tab: id}});
    },

    loadUser(id) {
      postForm('/h5/sa_company/detail', {id}).then(res => {
          if (res.status === 1) {
            this.user = res.data || {};
          } else {
            this.$message.error(res.msg);
          }
        });

    },
    loadOrder(id) {
      postForm('/api/b_sa_check/detailLists', {id}).then(res => {
          if (res.status === 1) {
            this.orderList = res.data || [];
          } else {
            this.$message.error(res.msg);
          }
        });

    },
    loadHit(id) {
      postForm('/api/b_sa_check/detailScoreH5', {id}).then(res => {
          if (res.status === 1) {
            const {list, score, userInfo={}, ...rest} = res.data;
            if (list) {
              this.checkResult = list || [];
            } else {
              this.checkResult = [rest['0']];
            }
            this.score = score;
          } else {
            this.$message.error(res.msg);
          }
        });

    },
  },
  created() {
    this.isVolvo = getRole().isVolvo;

    const {id, tab} = this.$router.currentRoute.query || {};
    this.id = id;
    this.curTab = tab || 't1';
    this.loadOrder(id);
    this.loadHit(id);
    if (!this.role.isAfSeller) {
      this.loadUser(id);
    }
  }
}
</script>

<style lang="less">

.page-reception-show-af {
  .box-info {
    margin: 0.8rem;
    padding: 0.5rem 1rem;
    font-size: 0.7rem;
    color: #fff;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background: #0070C0;
    p {
      margin: 0;
      padding: 0.3rem 0;
      display: flex;
      span {
        flex: 1;
      }

      strong {
        font-weight: normal;
        margin-right: 0.5rem;
      }
    }
  }

  .tbl-order {
    &.el-table, tr, .el-table__cell {
      background: transparent !important;
      line-height: 1.5;
      padding: 0.3rem 0.2rem;

      .cell {
        padding: 0;
      }
    }

    thead {
      th{
        border-bottom: 1px solid #D9D9D9 !important;
        color: #999999;
        font-size: 0.6rem;
        font-weight: normal;
      }
    }

    tbody {
      tr {
        td {
          font-size: 0.6rem;
          border: 0;
          color: #000;
          line-height: 1.2;
        }
      }
    }
  }

.box-score {
  position: relative;
  height: 15rem;
  .box-bg {
    margin: auto;
    height: 10rem;
    width: 15rem;
    position: relative;
    overflow: hidden;

    .bg-gray {
      background: #fff;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 7rem;
      height: 7rem;
      transform: translate(-50%, 50%);
      border-radius: 50%;
    }

    .ruling {
      position: absolute;
      bottom: -7.5rem;
      left: 0;
      width: 15rem;
      height: 15rem;

      i {
        position: absolute;
        top: 50%;
        left: 0rem;
        right: 0rem;
        height: 0.2rem;
        display: block;
        background: #fff;
      }
    }

    .round {
      position: absolute;
      bottom: -7.5rem;
      left: 0;
      transform-origin: center;
      width: 15rem;
      height: 15rem;
      i {
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(#F2F2F2 50%, #0070C0 50%);
        border-radius: 50%;
      }
    }
  }
    .mask {
      position: absolute;
      top: 5rem;
      left: 50%;
      transform: translate(-50%, 45%);
      background: url('~@/assets/bg_score_v.png') bottom center no-repeat;
      background-size: auto 85%;
      background: #FFFFFF;
      width: 5.1rem;
      height: 5.1rem;
      border-radius: 50%;
      box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
    }
    .score {
      text-align: center;
      font-size: 0.7rem;
      font-weight: 500;
      padding-top: 0.7rem;

      strong{
        font-size: 2.2rem;
        line-height: 1.1;
        color: #0070C0;
      }
    }

}

/* '#377EEA','#FAC15C','#11C16F','#ED5A54','#54BFED',*/
  .box-progress{
    margin-top: 1rem;
    padding: 0.5rem 0;
    border-top: 1px #EFEFEF solid;
    .score {
      display: flex;
      margin: 0.5rem 0;

      .c0, .c5 {
        div.el-progress__text {
          color: #377EEA;
        }
      }
      .c1, .c6 {
        div.el-progress__text {
          color: #FAC15C;
        }
      }
      .c2, .c7 {
        div.el-progress__text {
          color: #11C16F;
        }
      }
      .c3, .c8 {
        div.el-progress__text {
          color: #ED5A54;
        }
      }
      .c4, .c9 {
        div.el-progress__text {
          color: #54BFED;
        }
      }

      .circle {
        div.el-progress__text {
          font-size: 0.5rem !important;
        }
      }
      .line{
        flex: 1;
        padding-left: 0.5rem;
        .title {
          font-size: 0.6rem;
          font-weight: 400;
          color: #000;
        }

        .el-progress-bar {
          padding-right: 0;
        }
        .el-progress__text {
          position: absolute;
          top: -16px;
          right: 0;
        }
      }
    }
  }
.box-hit {
  padding: 0.8rem;
  .type {
    background: #E6F1F9;
    font-size: 0.8rem;
    color: #0070C0;
    line-height: 2rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    i {
      display: inline-block;
      width: 2px;
      height: 0.75rem;
      background: #0070C0;
      margin-right: 0.3rem;
    }
  }
  .box-item {
    background: #fff;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding: 0.1rem 0.5rem 0.8rem 0.5rem;
    .title {
      font-size: 0.8rem;
      margin-top: 0.5rem;
      margin-bottom: 0.3rem;
      font-weight: bold;
      color: #000;
    }
    .box-list {
      margin-top: 0.3rem;
      &:first-child {
        margin-top: 0;
      }
      .sub-title {
        font-size: 0.7rem;
        color: #000;
        font-weight: 500;
      }
    }
  }
  .box-tag {
    display: block;
    .tag{
      display: inline-block;
      margin: 0.3rem 0.5rem 0 0;
      padding: 0.1rem 0.5rem;
      background: #F5F5F5;
      border-radius: 4px;
      position: relative;
      font-size: 0.7rem;
      color: #222;

      .i-check {
        width: 0.7rem;
        height: 0.7rem;
        vertical-align: middle;
      }
    }
  }
}
  
  .box-top {
    height: 4.25rem;
    .fixed {
      z-index: 1000;
      background: url('~@/assets/af_bg_main.png') top center no-repeat;
      background-size: 100% auto;
      position: fixed;
      top: 0;
      width: 100%;
      max-width: 750px;
    }

    .top-bar {
      text-align: center;
      font-size: 0.8rem;
      font-weight: 400;
      padding: 1rem 0;

      a {
        position: absolute;
        left: 0.5rem;
        top: 1rem;
      }
    }
  }

  .box-tab {
    padding: 1rem 0.5rem;
    display: flex;
    font-size: 0.7rem;
    justify-content: space-between;
    span{
      display: inline-block;
      text-align: center;
      width: 30%;
      line-height: 2.25rem;
      border-radius: 2.25rem;
      color: #000;
      background: #D6D7DB;
    }
    span.sel{
      font-weight: 500;
      color: #FFFFFF;
      background: #0070C0;
    }
  }
}
</style>
